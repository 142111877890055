<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3>Espacio: {{ place.name }}</h3>
      </div>
      <div class="col-9 pt-0 pb-0">
        <v-chip
          color="green"
          text-color="white"
          class="ml-0 mr-2"
          label
        >
          Disponible
        </v-chip>
        <v-chip
          class="ma-2"
          text-color="white"
          color="#E3A728"
          label
        >
          Pre-reservado
        </v-chip>
        <v-chip
          class="ma-2"
          text-color="white"
          color="red"
          label
        >
          Reservado
        </v-chip>
        <v-chip
          class="ma-2"
          color="default"
          label
        >
          Seleccionado
        </v-chip>
      </div>
      <div class="col-3 text-right pt-0 pb-0">
        <v-btn color="primary" class="elevation-0 text-none" @click="$emit('reloadGeneralPlane')">
          Regresar al plano general
        </v-btn>
      </div>
    </div>
    <div class="d-flex justify-center mt-5">
      <div v-if="place">
        <konva-stage ref="stage" :config="{ width: place.planeWidth, height: place.planeHeight }">
          <konva-layer ref="layer">
            <konva-image :config="{ image: userPlaneImageUrl }"/>
            <div v-for="stand in stands"
                 :key="stand.id">
              <!-- SQUARE -->
              <konva-group
                v-if="!stand.cornerType"
                :config="{
                    x: Number(stand.xCoordinate),
                    y: Number(stand.yCoordinate),
                }"
                @mouseenter="handleMouseEnter"
                @mouseleave="handleMouseLeave"
                @click="bookStand(stand)"
              >
                <konva-rect
                  :config="{
                    x: 0,
                    y: 0,
                    width: Number(factor*stand.width),
                    height: Number(factor*stand.depth),
                    fill: 'white',
                    stroke: place.color
                  }"
                />
                <konva-text
                  :config="{
                    x: (Number(factor*stand.width) / 2) - (factor * stand.width > 50 ? 25 : 20),
                    y: (Number(factor*stand.depth) / 2) - 10,
                    text: stand.code.toUpperCase(),
                    fontSize: defineFont(stand),
                    fontFamily: 'Roboto',
                    fontStyle: 'bold',
                    fill: defineColor(stand),
                    padding: 5,
                    align: 'center'
                  }"
                />
              </konva-group>
              <!-- TOP LEFT -->
              <konva-group
                v-if="stand.cornerType === 'SIZQ'"
                :config="{
                    x: Number(stand.xCoordinate),
                    y: Number(stand.yCoordinate),
                }"
                @mouseenter="handleMouseEnter"
                @mouseleave="handleMouseLeave"
                @click="bookStand(stand)"
              >
                <konva-rect
                  :config="{
                    x: 0,
                    y: 0,
                    width: Number(factor*stand.width),
                    height: Number(factor*stand.depth),
                    fill: 'white',
                    stroke: place.color
                  }"
                />
                <konva-rect
                  :config="{
                    x: Number(factor*stand.width)  - Number(factor*stand.widthDifference),
                    y: Number(factor*stand.depth) - Number(factor*stand.depthDifference),
                    width: Number(factor*stand.widthDifference),
                    height: Number(factor*stand.depthDifference),
                    fill: 'white',
                    stroke: 'white',
                    strokeWidth: 4
                  }"
                />
                <konva-rect
                  :config="{
                    x: Number(factor*stand.width)  - Number(factor*stand.widthDifference) - 2,
                    y: Number(factor*stand.depth) - Number(factor*stand.depthDifference),
                    width: 2,
                    height: Number(factor*stand.depthDifference) + 1,
                    fill: place.color
                  }"
                />
                <konva-rect
                  :config="{
                    x: Number(factor*stand.width)  - Number(factor*stand.widthDifference) - 2,
                    y: Number(factor*stand.depth) - Number(factor*stand.depthDifference) - 2,
                    width: Number(factor*stand.widthDifference) + 3,
                    height: 2,
                    fill: place.color
                  }"
                />
                <konva-text
                  :config="{
                    x: (Number(factor*stand.width) / 2) - 25,
                    y: 20,
                    text: stand.code.toUpperCase(),
                     fontSize: defineFont(stand),
                    fontFamily: 'Roboto',
                    fontStyle: 'bold',
                    fill: defineColor(stand),
                    padding: 5,
                    align: 'center'
                  }"
                />
              </konva-group>
              <!-- TOP RIGHT -->
              <konva-group
                v-if="stand.cornerType === 'SDER'"
                :config="{
                    x: Number(stand.xCoordinate),
                    y: Number(stand.yCoordinate),
                }"
                @mouseenter="handleMouseEnter"
                @mouseleave="handleMouseLeave"
                @click="bookStand(stand)"
              >
                <konva-rect
                  :config="{
                    x: 0,
                    y: 0,
                    width: Number(factor*stand.width),
                    height: Number(factor*stand.depth),
                    fill: 'white',
                    stroke: place.color
                  }"
                />
                <konva-rect
                  :config="{
                    x: 0,
                    y: Number(factor*stand.depth) - Number(factor*stand.depthDifference),
                    width: Number(factor*stand.widthDifference),
                    height: Number(factor*stand.depthDifference),
                    fill: 'white',
                    stroke: 'white',
                    strokeWidth: 4
                  }"
                />
                <konva-rect
                  :config="{
                    x: Number(factor*stand.widthDifference),
                    y: Number(factor*stand.depth) - Number(factor*stand.depthDifference) - 2,
                    width: 2,
                    height: (Number(factor*stand.depthDifference)) + 3,
                    fill: place.color
                  }"
                />
                <konva-rect
                  :config="{
                    x: 0,
                    y: Number(factor*stand.depth) - Number(factor*stand.depthDifference) - 2,
                    width: (Number(factor*stand.widthDifference)) + 1,
                    height: 2,
                    fill: place.color
                  }"
                />
                <konva-text
                  :config="{
                    x: (Number(factor*stand.width) / 2) - 25,
                    y: 20,
                    text: stand.code.toUpperCase(),
                    fontSize: defineFont(stand),
                    fontFamily: 'Roboto',
                    fontStyle: 'bold',
                    fill: defineColor(stand),
                    padding: 5,
                    align: 'center'
                  }"
                />
              </konva-group>
              <!-- BOTTOM LEFT -->
              <konva-group
                v-if="stand.cornerType === 'IIZQ'"
                :config="{
                    x: Number(stand.xCoordinate),
                    y: Number(stand.yCoordinate),
                }"
                @mouseenter="handleMouseEnter"
                @mouseleave="handleMouseLeave"
                @click="bookStand(stand)"
              >
                <konva-rect
                  :config="{
                    x: 0,
                    y: 0,
                    width: Number(factor*stand.width),
                    height: Number(factor*stand.depth),
                    fill: 'white',
                    stroke: place.color
                  }"
                />
                <konva-rect
                  :config="{
                    x: Number(factor*stand.width)  - Number(factor*stand.widthDifference),
                    y: 0,
                    width: Number(factor*stand.widthDifference),
                    height: Number(factor*stand.depthDifference),
                    fill: 'white',
                    stroke: 'white',
                    strokeWidth: 4
                  }"
                />
                <konva-rect
                  :config="{
                    x: Number(factor*stand.width)  - Number(factor*stand.widthDifference) - 2,
                    y: 0,
                    width: 2,
                    height: Number(factor*stand.depthDifference) + 2,
                    fill: place.color
                  }"
                />
                <konva-rect
                  :config="{
                    x: Number(factor*stand.width)  - Number(factor*stand.widthDifference),
                    y: Number(factor*stand.depthDifference),
                    width: Number(factor*stand.widthDifference) + 1,
                    height: 2,
                    fill: place.color
                  }"
                />
                <konva-text
                  :config="{
                    x: (Number(factor*stand.width) / 2) - 25,
                    y: (Number(factor*stand.depth) / 2) + 20,
                    text: stand.code.toUpperCase(),
                    fontSize: defineFont(stand),
                    fontFamily: 'Roboto',
                    fontStyle: 'bold',
                    fill: defineColor(stand),
                    padding: 5,
                    align: 'center'
                  }"
                />
              </konva-group>
              <!-- BOTTOM RIGHT -->
              <konva-group
                v-if="stand.cornerType === 'IDER'"
                :config="{
                    x: Number(stand.xCoordinate),
                    y: Number(stand.yCoordinate),
                }"
                @mouseenter="handleMouseEnter"
                @mouseleave="handleMouseLeave"
                @click="bookStand(stand)"
              >
                <konva-rect
                  :config="{
                    x: 0,
                    y: 0,
                    width: Number(factor*stand.width),
                    height: Number(factor*stand.depth),
                    fill: 'white',
                    stroke: place.color
                  }"
                />
                <konva-rect
                  :config="{
                    x: 0,
                    y: 0,
                    width: Number(factor*stand.widthDifference),
                    height: Number(factor*stand.depthDifference),
                    fill: 'white',
                    stroke: 'white',
                    strokeWidth: 4
                  }"
                />
                <konva-rect
                  :config="{
                    x: Number(factor*stand.widthDifference),
                    y: 0,
                    width: 2,
                    height: Number(factor*stand.depthDifference),
                    fill: place.color
                  }"
                />
                <konva-rect
                  :config="{
                    x: 0,
                    y: Number(factor*stand.depthDifference),
                    width: Number(factor*stand.widthDifference) + 2,
                    height: 2,
                    fill: place.color
                  }"
                />
                <konva-text
                  :config="{
                    x: (Number(factor*stand.width) / 2) - 25,
                    y: (Number(factor*stand.depth) / 2) + 20,
                    text: stand.code.toUpperCase(),
                    fontSize: defineFont(stand),
                    fontFamily: 'Roboto',
                    fontStyle: 'bold',
                    fill: defineColor(stand),
                    padding: 5,
                    align: 'center'
                  }"
                />
              </konva-group>
            </div>
          </konva-layer>
        </konva-stage>
      </div>
    </div>
  </div>
</template>
<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'

export default {
  computed: {
    factor () {
      return 1200 / this.place.size
    }
  },
  name: 'PlacePlane',
  props: {
    place: {
      default: null,
      type: Object
    },
    userPlaneImageUrl: {
      default: null,
      type: HTMLImageElement
    },
    stands: {
      default: () => [],
      type: Array
    },
    selectedStands: {
      default: () => [],
      type: Array
    },
    bookStand: {
      type: Function
    }
  },
  methods: {
    defineColor (stand) {
      const isSelected = this.selectedStands.find(item => stand.id === item.id)
      if (isSelected) {
        return '#D8D8D8'
      }
      if (stand.preBookingCompany) {
        return '#E3A728'
      }
      return stand.status === 'available' ? '#53B257' : '#F44336'
    },
    defineFont (stand) {
      if (stand.width * this.factor > 50) {
        return 13
      }
      return 9
    },
    handleMouseEnter (e) {
      const stage = e.target.getStage()
      stage.container().style.cursor = 'pointer'
    },
    handleMouseLeave (e) {
      const stage = e.target.getStage()
      stage.container().style.cursor = 'default'
    }
  },
  mixins: [
    crudServiceMixin,
    loaderMixin,
    notificationMixin
  ]
}
</script>
