var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"identification","rules":"required|nit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"vid":"identification","type":"text","outlined":"","dense":"","error-messages":errors,"label":_vm.rutLabel +  ' (Sin código de verificación)',"required":""},on:{"blur":_vm.validateActiveBookings,"keypress":function($event){return _vm.isLetterOrNumber($event)}},model:{value:(_vm.model.identification),callback:function ($$v) {_vm.$set(_vm.model, "identification", $$v)},expression:"model.identification"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"Razón Social","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"vid":"businessName","type":"text","outlined":"","dense":"","error-messages":errors,"label":"Razón social","required":""},model:{value:(_vm.model.businessName),callback:function ($$v) {_vm.$set(_vm.model, "businessName", $$v)},expression:"model.businessName"}})]}}])})],1)]),_c('div',{staticClass:"row"},[(_vm.countries)?_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"País","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"vid":"countryId","error-messages":errors,"items":_vm.countries,"outlined":"","dense":"","item-text":"name","item-value":"id","label":"País"},on:{"change":_vm.getDepartments},model:{value:(_vm.model.countryId),callback:function ($$v) {_vm.$set(_vm.model, "countryId", $$v)},expression:"model.countryId"}})]}}],null,false,3948169763)})],1):_vm._e(),_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"Departamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"vid":"departmentId","error-messages":errors,"items":_vm.departments,"outlined":"","dense":"","item-text":"name","item-value":"id","label":"Departamento/Estado"},on:{"change":_vm.getCities},model:{value:(_vm.model.departmentId),callback:function ($$v) {_vm.$set(_vm.model, "departmentId", $$v)},expression:"model.departmentId"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"Ciudad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"vid":"cityId","error-messages":errors,"items":_vm.cities,"outlined":"","dense":"","item-text":"name","item-value":"id","label":"Ciudad"},model:{value:(_vm.model.cityId),callback:function ($$v) {_vm.$set(_vm.model, "cityId", $$v)},expression:"model.cityId"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"Nombre comercial","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"vid":"name","type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre comercial","required":""},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"Correo corporativo","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"vid":"email","type":"email","outlined":"","dense":"","error-messages":errors,"label":"Correo corporativo","required":""},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"name":"Teléfono de la empresa","rules":{required: true,phone: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{staticClass:"flex-wrap",attrs:{"label":"Teléfono de la empresa","error-messages":errors,"mode":"international","inputOptions":{showDialCode:false},"required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}})]}}])})],1),_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"name":"Extensión","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"vid":"phoneExtension","type":"text","outlined":"","dense":"","error-messages":errors,"label":"Extensión","required":""},model:{value:(_vm.model.phoneExtension),callback:function ($$v) {_vm.$set(_vm.model, "phoneExtension", $$v)},expression:"model.phoneExtension"}})]}}])})],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"Dirección","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"vid":"address","type":"text","outlined":"","dense":"","error-messages":errors,"label":"Dirección","required":""},model:{value:(_vm.model.address),callback:function ($$v) {_vm.$set(_vm.model, "address", $$v)},expression:"model.address"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"Página Web","rules":"max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"vid":"webSite","type":"text","outlined":"","dense":"","error-messages":errors,"label":"Página Web","required":""},model:{value:(_vm.model.webSite),callback:function ($$v) {_vm.$set(_vm.model, "webSite", $$v)},expression:"model.webSite"}})]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }